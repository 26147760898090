import React from 'react'
import './style/Impressum.css'
import { useEffect } from 'react'

const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='impressumContainer'>
      <h1>Impressum</h1>

      <h2>Angaben gemäß § 5 TMG</h2>
      <p>
        **Kaffeebrennerei - Peter Zwingler** <br />
        Innviertlerstraße 19 <br />
        4911 Tumeltsham <br />
        Österreich
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: +43 676/ 7700272 <br />
        E-Mail:{' '}
        <a href='mailto:office@kaffeebrennerei.at'>office@kaffeebrennerei.at</a>
      </p>

      <h2>Vertreten durch</h2>
      <p>Peter Zwingler</p>

      <h2>Umsatzsteuer-ID</h2>
      <p>ATU62191149</p>

      <h2>Haftung für Inhalte</h2>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
        diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
        bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
        übermittelte oder gespeicherte fremde Informationen zu überwachen oder
        nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
        hinweisen.
      </p>

      <h2>Haftung für Links</h2>
      <p>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
        Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich.
      </p>

      <h2>Urheberrecht</h2>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem österreichischen Urheberrecht. Die
        Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
        außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen
        Zustimmung des jeweiligen Autors bzw. Erstellers.
      </p>
    </div>
  )
}

export default Impressum
