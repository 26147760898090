import React, { useEffect } from 'react'
import HeroImg from '../resources/fotosKathi/24.jpg'
import Partner from '../components/Partner'
import Timeline from '../components/Timeline'
import './style/ÜberUnsPage.css'
import PeterCloseUp from '../resources/fotosKathi/8.jpg'

const ÜberUnsPage = abwesend => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='uberUnsPage'>
      <div
        className='productPageImageDiv'
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className='textDivProductPage'>
          <h1>Lernen Sie uns kennen!</h1>
          <p>
            Willkommen bei Kaffee Zwingler – wo Leidenschaft und Qualität in
            jeder Bohne stecken!
          </p>
        </div>
      </div>
      <div className='aboutContent'>
        <div className='aboutFounder'>
          <div className='aboutFounderText'>
            <h2>Peter Zwingler – Fairer Kaffee, schonend geröstet</h2>
            <p>
              Seit der Gründung von Kaffee Zwingler im Jahr 2010 steht für Peter
              eines im Mittelpunkt: Kaffee muss fair, nachhaltig und bekömmlich
              sein. Er setzt auf direkte Zusammenarbeit mit Kaffeebauern, faire
              Löhne und umweltschonende Anbaumethoden.
            </p>
            <p>
              Seine helle Röstung bewahrt die natürlichen Aromen und wertvollen
              Antioxidantien, während unerwünschte Bitterstoffe vermieden
              werden. Das Ergebnis: Ein Kaffee, der nicht nur großartig
              schmeckt, sondern auch gut tut.
            </p>
          </div>

          <img src={PeterCloseUp} alt='Peter Zwingler' />
        </div>
        <div className='timeLineDiv'>
          <h2>Unsere Geschichte</h2>
          <Timeline />
        </div>

        <Partner />
      </div>
    </div>
  )
}

export default ÜberUnsPage
