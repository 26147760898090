import React from 'react'
import { useEffect } from 'react'

import './style/Datenschutz.css'

const Datenschutz = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='datenschutzContainer'>
      <h1>Datenschutz</h1>
      <p>
        Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir
        verarbeiten Ihre Daten daher ausschließlich auf Grundlage der
        gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
        Datenschutzinformationen informieren wir Sie über die wichtigsten
        Aspekte der Datenverarbeitung im Rahmen unserer Website.
      </p>

      <h2>Cookies</h2>
      <p>
        Unsere Website verwendet Cookies, um das Angebot nutzerfreundlich zu
        gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis
        Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten
        Besuch wiederzuerkennen. Sie können Cookies in Ihrem Browser
        deaktivieren, was jedoch die Funktionalität der Website einschränken
        kann.
      </p>

      <h2>Kontakt mit uns</h2>
      <p>
        Wenn Sie per Formular oder E-Mail Kontakt mit uns aufnehmen, werden Ihre
        Daten für die Bearbeitung Ihrer Anfrage gespeichert. Diese Daten geben
        wir nicht ohne Ihre Einwilligung weiter.
      </p>

      <h2>Facebook-Plugins (Like-Button)</h2>
      <p>
        Unsere Website nutzt Plugins des sozialen Netzwerks Facebook. Durch die
        Verwendung des Like-Buttons können Daten an Facebook übermittelt werden.
        Weitere Informationen finden Sie in der Datenschutzerklärung von
        Facebook:{' '}
        <a
          href='https://de-de.facebook.com/policy.php'
          target='_blank'
          rel='noopener noreferrer'
        >
          Facebook Datenschutz
        </a>
        .
      </p>

      <h2>YouTube</h2>
      <p>
        Unsere Website nutzt Plugins der von Google betriebenen Plattform
        YouTube. Beim Besuch einer mit YouTube-Plugins ausgestatteten Seite wird
        eine Verbindung zu den YouTube-Servern hergestellt. Weitere
        Informationen finden Sie hier:{' '}
        <a
          href='https://www.google.de/intl/de/policies/privacy'
          target='_blank'
          rel='noopener noreferrer'
        >
          YouTube Datenschutz
        </a>
        .
      </p>

      <h2>Hosting</h2>
      <p>
        Dienst: Cloudways <br />
        Anbieter: Cloudways Ltd., 52 Springvale, Pope Pius XII Street, Mosta
        MST2653, Malta <br />
        Serverstandort: Frankfurt
      </p>

      <h2>Server-Log-Files</h2>
      <p>
        Unser Server speichert automatisch Informationen in Log-Files, darunter:
      </p>
      <ul>
        <li>Browsertyp und -version</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
      </ul>
      <p>
        Diese Daten sind nicht bestimmten Personen zuordenbar und werden nicht
        mit anderen Datenquellen zusammengeführt.
      </p>

      <h2>Haftung für Links</h2>
      <p>
        Unsere Website enthält Links zu externen Webseiten, für deren Inhalte
        wir keine Verantwortung übernehmen. Falls wir Kenntnis über
        Rechtsverletzungen erlangen, werden wir entsprechende Links umgehend
        entfernen.
      </p>

      <h2>Ihre Rechte</h2>
      <p>
        Ihnen stehen die Rechte auf Auskunft, Berichtigung, Löschung,
        Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Falls
        Sie eine Verletzung Ihrer Rechte vermuten, können Sie eine Beschwerde
        bei der österreichischen Datenschutzbehörde einreichen.
      </p>

      <h2>Kontakt</h2>
      <p>
        Peter Zwingler <br />
        Innviertlerstraße 19 <br />
        4911 Tumeltsham <br />
        <a href='mailto:office@kaffeebrennerei.at'>
          office@kaffeebrennerei.at
        </a>{' '}
        <br />
        Telefon: 0676 7700272
      </p>
    </div>
  )
}

export default Datenschutz
