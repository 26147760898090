import { useEffect, useState } from 'react'
import { collection, getDocs, doc, getDoc } from 'firebase/firestore'
import { db } from './firebaseConfig'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import Header from './components/Header'
import Hero from './components/Hero'
import Unternehmeswerte from './components/Unternehemswerte'
import ProduktePage from './pages/ProduktePage'
import AktuellesPage from './pages/AktuellesPage'
import Footer from './components/Footer'
import Datenschutz from './pages/Datenschutz'
import Impressum from './pages/Impressum'
import ProductDetail from './pages/ProductDetail'
import ÜberUnsPage from './pages/ÜberUnsPage'
import KontaktPage from './pages/KontaktPage'
import Cart from './pages/Cart'
import Checkout from './pages/CheckoutPage'
import Bestseller from './components/Bestseller'
import CookieBanner from './components/CookieBanner'
import AbwesendBanner from './components/AbwesendBanner'
import SlideShow from './components/SlideShow'

function App () {
  const [products, setProducts] = useState([])
  const [blogPosts, setBlogPosts] = useState([])
  const [abwesend, setAbwesend] = useState(false)
  const [abwesendMessage, setAbwesendMessage] = useState('')

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent')
    if (!cookieConsent) {
      setIsVisible(true)
    }
  }, [])

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'products'))
        const items = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))

        setProducts(items)
        console.log('Fetched Products:', items)
      } catch (error) {
        console.error('Error fetching products: ', error)
      }
    }

    const fetchBlogPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'blogs'))
        const items = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))

        setBlogPosts(items)
        console.log('Fetched Blog Posts:', items)
      } catch (error) {
        console.error('Error fetching blog posts: ', error)
      }
    }

    const fetchAbwesend = async () => {
      try {
        const docRef = doc(db, 'settings', 'abwesenheit')
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
          setAbwesend(docSnap.data().abwesenheit)
          setAbwesendMessage(docSnap.data().nachricht)

          console.log('Fetched Abwesenheit:', docSnap.data().abwesenheit)
        } else {
          console.warn('No such document!')
        }
      } catch (error) {
        console.error('Error fetching abwesenheit: ', error)
      }
    }

    fetchAbwesend()
    fetchBlogPosts()
    fetchProducts()
  }, [])

  return (
    <Router>
      <div className='App'>
        {isVisible && <CookieBanner />}
        <Header />
        <Routes>
          <Route
            path='/'
            element={
              <>
                <section id='hero'>
                  <Hero />
                </section>
                {abwesend && (
                  <section id='abwesend'>
                    <AbwesendBanner message={abwesendMessage} />
                  </section>
                )}
                <section id='unternehmenswerte'>
                  <Unternehmeswerte />
                </section>
                {/* <section id='bestseller'>
                  {products.length > 0 && <Bestseller product={products[0]} />}
                </section> */}
                <section id='slideshow'>
                  <SlideShow />
                </section>
              </>
            }
          />
          <Route
            path='/produkte'
            element={<ProduktePage produkte={products} />}
          />
          <Route
            path='/produkt/:id'
            element={<ProductDetail produkte={products} />}
          />
          <Route
            path='/aktuelles'
            element={<AktuellesPage blogPosts={blogPosts} />}
          />

          <Route
            path='/ueber-uns'
            element={<ÜberUnsPage abwesend={abwesend} />}
          />
          <Route path='/kontakt' element={<KontaktPage />} />
          <Route path='/impressum' element={<Impressum />} />
          <Route path='/datenschutz' element={<Datenschutz />} />
          <Route path='/warenkorb' element={<Cart abwesend={abwesend} />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='*' element={<Hero />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}

export default App
