import React from 'react'
import { useState } from 'react'
import './style/BlogPost.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const BlogPost = ({ post }) => {
  console.log(post.imageUrls)
  const [imageIndex, setImageIndex] = useState(null)

  return (
    <div className='blogPostContainer'>
      <div className='blogPostTitleAndDate'>
        <p>{post.date}</p>
        <h3>{post.title}</h3>
      </div>
      <div className='blogPost'>
        {imageIndex !== null && (
          <div className='imageCloseUp'>
            <img
              onClick={() => setImageIndex(null)}
              src={post.imageUrls[imageIndex]}
              alt={post.title}
            />
            <div className='arrowContainer'>
              {imageIndex > 0 && (
                <button
                  className='arrowButton'
                  style={{ left: '25px' }}
                  onClick={() => setImageIndex(imageIndex - 1)}
                >
                  <ArrowBackIcon />
                </button>
              )}
              {imageIndex < post.imageUrls.length - 1 && (
                <button
                  className='arrowButton'
                  style={{ right: '25px' }}
                  onClick={() => setImageIndex(imageIndex + 1)}
                >
                  <ArrowForwardIcon />
                </button>
              )}
            </div>
          </div>
        )}
        {/* 🔹 Bild-Container */}
        <div className='blogPostImageDiv'>
          {post.imageUrls &&
            post.imageUrls.length > 0 &&
            post.imageUrls.map((image, index) => (
              <img
                onClick={() => setImageIndex(index)}
                key={index}
                src={image}
                alt={post.title}
                className='blogPostImage'
              />
            ))}
        </div>

        {/* 🔹 Info-Container */}
        <div className='blogPostInfoDiv'>
          <p>{post.content}</p>
        </div>
      </div>
    </div>
  )
}

export default BlogPost
