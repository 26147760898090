import React from 'react'
import './style/Timeline.css'
import IMG from '../resources/fotosKathi/7.jpg'

const milestones = [
  {
    year: '2010',
    text: 'Gründung von Kaffee Zwingler mit dem Ziel, nachhaltigen und fair gehandelten Kaffee zu rösten.'
  },
  {
    year: '2015',
    text: 'Aufbau enger Partnerschaften mit Kaffeebauern für direkte & faire Handelsbeziehungen.'
  },
  {
    year: '2020',
    text: 'Einführung umweltfreundlicher Verpackungen & nachhaltiger Prozesse in der Produktion.'
  },
  {
    year: 'Heute',
    text: 'Wachsende Community von Kaffeeliebhabern & kontinuierliche Verfeinerung der Röstmethoden.'
  }
]

const Timeline = () => {
  return (
    <div className='timeline-container'>
      {/* Bildbereich */}
      <div className='timeline-image'>
        <img src={IMG} alt='Peter Zwingler' />
      </div>

      {/* Timeline-Bereich */}
      <div className='timeline'>
        {milestones.map((milestone, index) => (
          <div key={index} className='timeline-item'>
            <div className='timeline-year'>{milestone.year}</div>
            <div className='timeline-content'>{milestone.text}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Timeline
