// src/firebase.js
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyD8fDNf4u275BJaOoetAof0Qq6tBEUeAlg',
  authDomain: 'kaffeeroesterei-8b089.firebaseapp.com',
  projectId: 'kaffeeroesterei-8b089',
  storageBucket: 'kaffeeroesterei-8b089.firebasestorage.app',
  messagingSenderId: '304863779329',
  appId: '1:304863779329:web:91eedc6ace53a36efcbe00'
}


const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { db }
