import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../resources/Zwingler_Logo_nurSymbol_300dpNoBackgroundi.png'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import cartIcon from '../resources/Icons/cartIcon.png'
import './style/Header.css'

const Header = () => {
  const [screenwidth, setScreenwidth] = useState(window.innerWidth)
  const [menuOpen, setMenuOpen] = useState(false)
  const [cartItemCount, setCartItemCount] = useState(0)

  // Warenkorb aus localStorage auslesen
  useEffect(() => {
    const updateCartCount = () => {
      const cart = JSON.parse(localStorage.getItem('cart')) || []
      setCartItemCount(cart.length)
    }

    // Initial abrufen
    updateCartCount()

    // Event Listener für Änderungen im localStorage (anderer Tab)
    const handleStorageChange = event => {
      if (event.key === 'cart') {
        updateCartCount()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    // Mutation Observer für lokale Änderungen im selben Tab
    const observer = new MutationObserver(updateCartCount)
    observer.observe(document, { subtree: true, childList: true })

    return () => {
      window.removeEventListener('storage', handleStorageChange)
      observer.disconnect()
    }
  }, [])

  // Bildschirmgröße aktualisieren
  useEffect(() => {
    const handleResize = () => setScreenwidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (screenwidth > 800) {
    return (
      <div className='header'>
        <div className='logo'>
          <Link to='/'>
            <img src={Logo} alt='Logo' />
          </Link>
        </div>
        <div className='links'>
          {/* <Link to='/produkte'>Produkte</Link>
          <Link to='/aktuelles'>Aktuelles</Link>
          <Link to='/ueber-uns'>Über uns</Link>
          <Link to='/kontakt'>Kontakt</Link> */}
          <Link to='/'>Produkte</Link>
          <Link to='/'>Aktuelles</Link>
          <Link to='/'>Über uns</Link>
          <Link to='/'>Kontakt</Link>
        </div>
        <div className='linksIcons'>
          {/* <Link to='/warenkorb'>
            <img src={cartIcon} alt='cartIcon' className='iconHeader' />
            {cartItemCount > 0 && (
              <span className='cartBadge'>{cartItemCount}</span>
            )}
          </Link> */}
          <Link to='/'>
            <img src={cartIcon} alt='cartIcon' className='iconHeader' />
            {cartItemCount > 0 && (
              <span className='cartBadge'>{cartItemCount}</span>
            )}
          </Link>
        </div>
      </div>
    )
  } else {
    return (
      <div className={screenwidth > 800 ? 'outerHeader' : 'outerHeaderMobile'}>
        <div className='header'>
          <div className='logo'>
            <Link to='/'>
              <img src={Logo} alt='Logo' />
            </Link>
          </div>

          {/* <button
            className='burgerButtonHeader'
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <MenuIcon sx={{ fontSize: '1.5rem', color: 'black' }} />
          </button> */}
          <button
            className='burgerButtonHeader'
            onClick={() => setMenuOpen(false)}
          >
            <MenuIcon sx={{ fontSize: '1.5rem', color: 'black' }} />
          </button>
        </div>
        {menuOpen && (
          <div className='menuOpenDiv'>
            <div className='linksMobile'>
              <Link to='/produkte' onClick={() => setMenuOpen(false)}>
                Produkte
              </Link>
              <Link to='/aktuelles' onClick={() => setMenuOpen(false)}>
                Aktuelles
              </Link>
              <Link to='/ueber-uns' onClick={() => setMenuOpen(false)}>
                Über uns
              </Link>
              <Link to='/kontakt' onClick={() => setMenuOpen(false)}>
                Kontakt
              </Link>
              <Link to='/warenkorb' onClick={() => setMenuOpen(false)}>
                <img src={cartIcon} alt='cartIcon' className='iconHeader' />
              </Link>
              <button
                className='burgerButtonHeader closeButtonHeader'
                onClick={() => setMenuOpen(false)}
              >
                <CloseIcon sx={{ fontSize: '1.5rem' }} />
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Header
