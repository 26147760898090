import React from 'react'
import { useNavigate } from 'react-router-dom'
import './style/Product.css'

const Product = ({ produkt }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/produkt/${produkt.id}`) // Navigiert zur Produkt-Detailseite
  }

  return (
    <div key={produkt.id} className='produktCard' onClick={handleClick}>
      {produkt.images && produkt.images.length > 0 && (
        <img
          src={produkt.images[0]}
          alt={produkt.name}
          className='produktBild'
        />
      )}
      <div className='infoDivProduct'>
        <h2>{produkt.name}</h2>
        <p>{produkt.price} €</p>
      </div>
    </div>
  )
}

export default Product
