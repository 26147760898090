import React from 'react'
import './style/Unternehmeswerte.css'
import coffee from '../resources/unternehmenswerte/coffee.png'
import etnisch from '../resources/unternehmenswerte/etnisch.png'
import fair from '../resources/unternehmenswerte/fair.png'
import quality from '../resources/unternehmenswerte/quality.png'
import gesund from '../resources/unternehmenswerte/gesund.png'

const Unternehmeswerte = () => {
  return (
    <div className='unternehmenswerteDiv'>
      <div className='unternehmenswert'>
        <p>Gesund - helle Röstung</p>
        <img src={gesund} />
      </div>
      <div className='unternehmenswert'>
        <p>Etnisch produziert</p>
        <img src={etnisch} />
      </div>{' '}
      <div className='unternehmenswert'>
        <p>Fair gehandelt</p>
        <img src={fair} />
      </div>{' '}
      <div className='unternehmenswert'>
        <p>Hohe Qualität</p>
        <img src={quality} />
      </div>
    </div>
  )
}

export default Unternehmeswerte
