import React from 'react'
import './style/Footer.css'
import { Link } from 'react-router-dom'

import InstagramIcon from '@mui/icons-material/Instagram'
import XIcon from '@mui/icons-material/X'
import FacebookIcon from '@mui/icons-material/Facebook'

const Footer = () => {
  return (
    <footer className='footerDiv'>
      <div className='footerContent'>
        <div className='footerSection'>
          <h4>Über uns</h4>
          <p>
            Handgerösteter Kaffee aus Leidenschaft. Qualität, die man schmeckt!
          </p>
        </div>

        <div className='footerSection'>
          <h4>Kontakt</h4>
          <p>Email: office@kaffeebrennerei.at</p>
          <p>Telefon: +43 676/ 7700272</p>
          <p>Adresse: Innviertlerstr. 19, 4911 Tumeltsham</p>
        </div>

        <div className='footerSection'>
          <h4>Folge uns</h4>
          <div className='socialIcons'>
            <a href='#' className='socialIcon'>
              <XIcon />
            </a>
            <a href='#' className='socialIcon'>
              <InstagramIcon />
            </a>
            <a href='#' className='socialIcon'>
              <FacebookIcon />
            </a>
          </div>
        </div>
      </div>

      <div className='footerBottom'>
        <p>© 2025 Kaffeebrennerei Peter Zwingler. Alle Rechte vorbehalten.</p>
        <div className='linkDivFooter'>
          <Link to='/impressum'>Impressum</Link>

          <Link to='/datenschutz'>Datenschutz</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
