import React, { useState, useEffect, use } from 'react'
import './style/Checkout.css'
import { useNavigate } from 'react-router-dom'
import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs
} from 'firebase/firestore'
import HeroImg from '../resources/fotosKathi/18.jpg'

import { db } from '../firebaseConfig'

const Checkout = () => {
  const navigate = useNavigate()
  const [shippingOrPickup, setShippingOrPickup] = useState('shipping')
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    ...(shippingOrPickup === 'shipping' && {
      address: '',
      city: '',
      postalCode: '',
      country: ''
    })
  })

  const [cartItems, setCartItems] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [orderConfirmation, setOrderConfirmation] = useState(false)
  const [orderWeight, setOrderWeight] = useState(0)
  const [shippingCost, setShippingCost] = useState(4.99)
  const grandTotal = totalPrice + shippingCost
  const [shippingRules, setShippingRules] = useState([])

  useEffect(() => {
    const fetchShippingRules = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'shippingRules'))
        const fetchedRules = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))

        // Sortiere die Regeln nach Gewicht und speichere sie im State
        setShippingRules(fetchedRules.sort((a, b) => a.weight - b.weight))
        console.log('Versandregeln:', fetchedRules)
      } catch (error) {
        console.error('Fehler beim Abrufen der Versandregeln:', error)
      }
    }

    fetchShippingRules()
  }, [])

  useEffect(() => {
    if (!cartItems || cartItems.length === 0 || shippingRules.length === 0)
      return // Sicherstellen, dass cartItems und shippingRules existieren

    let totalWeight = 0

    cartItems.forEach(cartItem => {
      const { selectedOptions } = cartItem
      if (selectedOptions) {
        const menge = parseFloat(selectedOptions.menge) || 0
        const stueckzahl = parseInt(selectedOptions.stueckzahl, 10) || 0

        totalWeight += menge * stueckzahl
      }
    })

    // Standardversandkosten (falls keine passende Regel gefunden wird)
    let cost = 4.99

    // Regeln durchgehen und passende Versandkosten setzen
    for (let rule of shippingRules) {
      if (totalWeight >= rule.weight) {
        cost = rule.cost
      }
    }

    setShippingCost(cost)
    setOrderWeight(totalWeight)
    console.log('Gesamtgewicht:', totalWeight)
    console.log('Versandkosten:', cost)
  }, [cartItems, shippingRules])

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || []
    setCartItems(storedCart)
    const total = storedCart.reduce(
      (acc, item) => acc + item.price * item.selectedOptions.stueckzahl,
      0
    )
    setTotalPrice(total)
  }, [])

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (cartItems.length === 0) {
      alert('Ihr Warenkorb ist leer.')
      return
    }

    const orderData = {
      customer: formData,
      items: cartItems,
      total: grandTotal,
      timestamp: serverTimestamp()
    }

    try {
      const docRef = await addDoc(collection(db, 'orders'), orderData)
      console.log('Order written with ID: ', docRef.id)
    } catch (error) {
      console.error('Error adding document: ', error)
    }

    const emailData = {
      sender: {
        name: 'Zwingler Mailservice',
        email: 'xaver.ebetshuber@gmail.com'
      },
      to: [{ email: 'xaver.ebetshuber@gmail.com', name: 'Empfänger' }],
      subject: 'Neue Bestellung erhalten',
      htmlContent: `
        <h3>Neue Bestellung von ${formData.firstName} ${formData.lastName}</h3>
        <p>Email: ${formData.email}</p>
        ${
          shippingOrPickup === 'shipping'
            ? `<p>Adresse: ${formData.address}, ${formData.city}, ${formData.postalCode}, ${formData.country}</p>`
            : `<p><strong>Der Kunde möchte die Bestellung abholen.</strong></p>`
        }
        <h4>Bestellte Artikel:</h4>
        <ul>
          ${cartItems
            .map(
              item => `
                <li>
                  <strong>${item.selectedOptions.stueckzahl}x ${item.name} - ${
                item.price
              } €</strong><br/>
                  Mahlgrad: ${item.selectedOptions.mahlgrad}<br/>
                  Größe: ${item.selectedOptions.menge}<br/>
                  ${
                    item.selectedOptions.mahlgrad === 'gemahlen'
                      ? `Kaffeeart: ${item.selectedOptions.kaffeeart}<br/>`
                      : ''
                  }
                </li>
              `
            )
            .join('')}
        </ul>
        <h3>Gesamtsumme: ${grandTotal.toFixed(2)} €</h3>
        <p><strong>Gesamtgewicht der Bestellung:</strong> ${orderWeight} g</p>
        <p><strong>Versandkosten:</strong> ${shippingCost.toFixed(2)} €</p>
      `
    }

    try {
      const response = await fetch('https://api.brevo.com/v3/smtp/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key':
            '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'
        },
        body: JSON.stringify(emailData)
      })

      const result = await response.json()
      console.log('Brevo Response:', result)

      if (response.ok) {
        localStorage.removeItem('cart')
        setOrderConfirmation(true)
      } else {
        alert(
          'Fehler beim absenden der Bestellung. Versuchen Sie es noch einmal in ein paar Minuten.'
        )
      }
    } catch (error) {
      console.error('Fehler beim Senden:', error)
      alert(
        'Fehler beim Senden der Bestellung. Versuchen Sie es noch einmal in ein paar Minuten.'
      )
    }
  }

  return (
    <div style={{ backgroundColor: '#fcfbf7' }}>
      <div
        className='aktuellesPageImageDiv'
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className='textDivAktuellesPage'>
          <h1>Checkout</h1>
          <p>Wir freuen uns über Ihre Bestellung!</p>
        </div>
      </div>
      <div className='checkout-container'>
        {orderConfirmation && (
          <div className='order-confirmation'>
            <div>
              {' '}
              <h2>Vielen Dank für Ihre Bestellung!</h2>
              <p>
                Sie erhalten in Kürze eine Bestätigung per E-Mail. (1-5
                Werktage)
              </p>
              <button onClick={() => navigate('/')}>
                Zurück zur Startseite
              </button>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit} className='checkout-form'>
          <div className='customer-info'>
            <h2>Kundendaten</h2>
            <label>Vorname:</label>
            <input
              type='text'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              required
            />

            <label>Nachname:</label>
            <input
              type='text'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              required
            />

            <label>Email:</label>
            <input
              type='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className='shipping-or-pickup-button'>
            <h2>Versandart</h2>
            <button
              type='button'
              onClick={() => setShippingOrPickup('pickup')}
              className={`checkout-button-shipping ${
                shippingOrPickup === 'pickup' ? 'active' : ''
              }`}
            >
              🚗 Abholung
            </button>
            <button
              type='button'
              className={`checkout-button-shipping ${
                shippingOrPickup === 'shipping' ? 'active' : ''
              }`}
              onClick={() => setShippingOrPickup('shipping')}
              disabled={shippingOrPickup === 'shipping'}
            >
              📦 Versand
            </button>
          </div>

          {shippingOrPickup === 'shipping' ? (
            <div className='shipping-info'>
              <h2>Lieferadresse</h2>
              <label>Adresse:</label>
              <input
                type='text'
                name='address'
                value={formData.address}
                onChange={handleChange}
                required
              />

              <label>Stadt:</label>
              <input
                type='text'
                name='city'
                value={formData.city}
                onChange={handleChange}
                required
              />

              <label>PLZ:</label>
              <input
                type='text'
                name='postalCode'
                value={formData.postalCode}
                onChange={handleChange}
                required
              />

              <label>Land:</label>
              <input
                type='text'
                name='country'
                value={formData.country}
                onChange={handleChange}
                required
              />
            </div>
          ) : (
            <div className='shipping-info'>
              <h2>Abholadresse</h2>
              <p>
                Zwingler Kaffee
                <br />
                Musterstraße 123
                <br />
                12345 Musterstadt
              </p>
              <p>
                Abholung ist nur nach Terminvereinbarung möglich. Sie bekommen
                eine Email mit weiteren Informationen!
              </p>
            </div>
          )}

          <button type='submit' className='checkout-button'>
            Bestellung abschicken
          </button>
        </form>
        <div className='order-summary'>
          <h2>Bestellübersicht</h2>
          <ul>
            {cartItems.map((item, index) => (
              <li key={index} className='order-item'>
                <img
                  src={item.images[0]}
                  alt={item.name}
                  className='order-item-image'
                />
                <div className='order-item-details'>
                  <h3>{item.name}</h3>
                  <p>
                    {item.selectedOptions.stueckzahl}x{' '}
                    {item.selectedOptions.menge}
                  </p>
                  <p>{item.price.toFixed(2)} €</p>
                </div>
              </li>
            ))}
          </ul>
          <div className='order-summary-total'>
            <p>Zwischensumme: {totalPrice.toFixed(2)} €</p>
            <p>
              Versandkosten ({orderWeight && orderWeight / 1000} kg):{' '}
              {shippingCost.toFixed(2)} €
            </p>
            <h3>Gesamt: {grandTotal.toFixed(2)} €</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout
