import React, { useState, useEffect } from 'react'
import AktuellesPageImg from '../resources/fotosKathi/6.jpg'
import BlogPost from '../components/BlogPost'

import './style/AktuellesPage.css'

const AktuellesPage = ({ blogPosts }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [sortOrder, setSortOrder] = useState('desc') // Standard: Neueste zuerst

  // Blog-Posts filtern und sortieren
  const sortedPosts = [...blogPosts]
    .filter(post => post.public) // Nur öffentliche Beiträge anzeigen
    .sort((a, b) => {
      return sortOrder === 'desc'
        ? new Date(b.date) - new Date(a.date) // Neueste zuerst
        : new Date(a.date) - new Date(b.date) // Älteste zuerst
    })

  return (
    <div className='aktuellesPage'>
      <div
        className='aktuellesPageImageDiv'
        style={{ backgroundImage: `url(${AktuellesPageImg})` }}
      >
        <div className='textDivAktuellesPage'>
          <h1>Aktuelles bei der Kaffeebrennerei</h1>
          <p>
            Hier findest du die neuesten Blog-Beiträge unserer Kaffeerösterei.
          </p>
        </div>
      </div>

      {/* 🔹 Sortieroption hinzufügen */}
      <div className='sortContainer'>
        <label htmlFor='sortOrder'>Sortieren nach:</label>
        <select
          id='sortOrder'
          value={sortOrder}
          onChange={e => setSortOrder(e.target.value)}
        >
          <option value='desc'>Neueste zuerst</option>
          <option value='asc'>Älteste zuerst</option>
        </select>
      </div>

      <div className='blogList'>
        {sortedPosts.map(post => (
          <BlogPost key={post.id} post={post} />
        ))}
      </div>
    </div>
  )
}

export default AktuellesPage
