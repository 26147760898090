import React, { useState, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import './style/ProductDetail.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const ProductDetail = ({ produkte }) => {
  const { id } = useParams() // Holt sich die ID aus der URL
  const produkt = produkte.find(p => p.id === id) // Produkt anhand der ID finden
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [currentFullSizeViewImage, setCurrentFullSizeViewImage] = useState(null)
  const [cartConfirmationMessage, setCartConfirmationMessage] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState({
    kaffeeart: 'siebträger',
    mahlgrad: 'ganze Bohne',
    menge: '250g',
    stueckzahl: 1
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleAddToCart = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || []

    // Produkt mit exakt denselben Optionen finden
    const existingItem = cart.find(
      item =>
        item.id === produkt.id &&
        item.selectedOptions.kaffeeart === selectedOptions.kaffeeart &&
        item.selectedOptions.mahlgrad === selectedOptions.mahlgrad &&
        item.selectedOptions.menge === selectedOptions.menge
    )

    if (existingItem) {
      // Falls das Produkt existiert, die Stückzahl erhöhen
      existingItem.selectedOptions.stueckzahl += selectedOptions.stueckzahl
    } else {
      // Neues Produkt in den Warenkorb hinzufügen
      cart.push({ ...produkt, selectedOptions: { ...selectedOptions } })
    }

    // **WICHTIG: Den aktualisierten Warenkorb im LocalStorage speichern**
    localStorage.setItem('cart', JSON.stringify(cart))

    // Bestätigungsnachricht setzen
    setCartConfirmationMessage(true)
  }

  if (!produkt) {
    return <h2>Produkt nicht gefunden</h2>
  }

  return (
    <div className='productDetailContainer'>
      {cartConfirmationMessage && (
        <div className='cartConfirmationMessage'>
          <div className='cartConfirmationMessageInnerDiv'>
            {' '}
            <p>Produkt wurde in den Warenkorb gelegt!</p>
            <button onClick={() => window.location.replace('/produkte')}>
              Zurück zur Produkseite
            </button>
          </div>
        </div>
      )}
      {currentFullSizeViewImage !== null && (
        <div className='fullSizeImageContainer'>
          <div className='arrowContainer'>
            {currentFullSizeViewImage > 0 && (
              <button
                className='arrowButton'
                style={{ left: '25px' }}
                onClick={() =>
                  setCurrentFullSizeViewImage(currentFullSizeViewImage - 1)
                }
              >
                <ArrowBackIcon />
              </button>
            )}
            {currentFullSizeViewImage < produkt.images.length - 1 && (
              <button
                className='arrowButton'
                style={{ right: '25px' }}
                onClick={() =>
                  setCurrentFullSizeViewImage(currentFullSizeViewImage + 1)
                }
              >
                <ArrowForwardIcon />
              </button>
            )}
          </div>
          <img
            src={produkt.images[currentFullSizeViewImage]}
            alt={produkt.name}
            onClick={() => setCurrentFullSizeViewImage(null)}
          />
        </div>
      )}
      <div className='productImageContainer'>
        {/* 🔹 Hauptbild */}
        <div className='productThumbnaileImageContainer'>
          {produkt.images && produkt.images.length > 0 && (
            <img
              src={produkt.images[currentImageIndex]}
              alt={produkt.name}
              className='productMainImage'
              onClick={() => setCurrentFullSizeViewImage(currentImageIndex)}
            />
          )}
        </div>

        {/* 🔹 Thumbnail-Leiste */}
        <div className='productMainImageContainer'>
          {produkt.images &&
            produkt.images.length > 1 &&
            produkt.images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={produkt.name}
                className='thumbnailImage'
                onClick={() => setCurrentImageIndex(index)} // Klick aktualisiert das Hauptbild
                style={
                  currentImageIndex === index
                    ? { border: '3px solid #8b4513' }
                    : {}
                }
              />
            ))}
        </div>
      </div>

      {/* 🔹 Produkt-Details */}
      <div className='productDetailInfoContainer'>
        <h1>{produkt.name}</h1>
        <p className='productDescription'>{produkt.description}</p>
        <p className='productPrice'>{produkt.price} €</p>

        {/* 🔹 Auswahlfelder */}

        <div className='product-detail-container'>
          <div className='select-group'>
            <label>Mahlgrad</label>
            <select
              value={selectedOptions.mahlgrad}
              onChange={e =>
                setSelectedOptions({
                  ...selectedOptions,
                  mahlgrad: e.target.value
                })
              }
            >
              <option value='ganze Bohne'>Ganze Bohne</option>
              <option value='gemahlen'>Gemahlen</option>
            </select>
          </div>
          {selectedOptions.mahlgrad === 'gemahlen' && (
            <div className='select-group' style={{ marginBottom: '20px' }}>
              <label>Kaffeeart</label>
              <select
                value={selectedOptions.kaffeeart}
                onChange={e =>
                  setSelectedOptions({
                    ...selectedOptions,
                    kaffeeart: e.target.value
                  })
                }
              >
                <option value='siebträger'>Siebträger</option>
                <option value='filter'>Filter</option>
                <option value='espresso'>Espresso</option>
              </select>
            </div>
          )}
          <div className='select-group'>
            <label>Menge</label>
            <select
              value={selectedOptions.menge}
              onChange={e =>
                setSelectedOptions({
                  ...selectedOptions,
                  menge: e.target.value
                })
              }
            >
              <option value='250g'>250g</option>
              <option value='500g'>500g</option>
              <option value='1000g'>1000g</option>
            </select>
          </div>
          <div className='select-group'>
            <label>Stückzahl</label>
            <input
              type='number'
              value={selectedOptions.stueckzahl}
              onChange={e =>
                setSelectedOptions({
                  ...selectedOptions,
                  stueckzahl: Number(e.target.value)
                })
              }
              min='1'
            />
          </div>

          {/* 🔹 In den Warenkorb Button */}
          <button className='product-detail-button' onClick={handleAddToCart}>
            In den Warenkorb
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductDetail
