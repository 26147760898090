import React from 'react'
import './style/AbwesendBanner.css'

const AbwesendBanner = ({ message }) => {
  return (
    <div className='abwesend-banner'>
      <h1>Wir sind zurzeit nicht verfügbar!</h1>
      <p>{message}</p>
    </div>
  )
}

export default AbwesendBanner
