import React, { useState, useEffect } from 'react'
import './style/ProductPage.css'
import Product from '../components/Product'
import HeroImg from '../resources/fotosKathi/5.jpg'
import SearchIcon from '@mui/icons-material/Search'

const ProduktePage = ({ produkte }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [searchTerm, setSearchTerm] = useState('')

  // Produkte filtern basierend auf dem Suchbegriff
  const filteredProdukte = produkte.filter(produkt =>
    produkt.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className='productPage'>
      <div
        className='productPageImageDiv'
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className='textDivProductPage'>
          <h1>Willkommen bei der Kaffeebrennerei</h1>
          <p>Stöbere gern unsere Kaffeesorten durch!</p>
        </div>
      </div>

      {/* 🔹 Suchleiste */}
      <div className='searchContainer'>
        <input
          type='text'
          placeholder='Nach Kaffee suchen...'
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <SearchIcon sx={{ fontSize: '1.8rem' }} />
      </div>

      <div className='productList'>
        {filteredProdukte.filter(produkt => produkt.public).length > 0 ? ( // Filtert nur Produkte, bei denen "public" true ist
          filteredProdukte
            .filter(produkt => produkt.public) // Erneut filtern, um nur öffentliche Produkte anzuzeigen
            .map(produkt => <Product key={produkt.id} produkt={produkt} />)
        ) : (
          <p className='noResults'>Kein Produkt gefunden.</p>
        )}
      </div>
    </div>
  )
}

export default ProduktePage
