import { useState, useEffect } from 'react'
import './style/CookieBanner.css'

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent')
    if (!cookieConsent) {
      setIsVisible(true)
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true')
    setIsVisible(false)
  }

  return (
    isVisible && (
      <div className='outer-cookie-banner'>
        <div className='cookie-banner'>
          <p>
            Diese Website verwendet Cookies, um die Benutzererfahrung zu
            verbessern.
          </p>
          <button className='accept-button' onClick={handleAccept}>
            Akzeptieren
          </button>
        </div>
      </div>
    )
  )
}

export default CookieBanner
