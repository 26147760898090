import React, { use } from 'react'
import HeroImg from '../resources/fotosKathi/12.jpg'
import { useNavigate } from 'react-router-dom'

import './style/Hero.css'

const Hero = () => {
  const navigate = useNavigate()

  return (
    <div className='heroDiv' style={{ backgroundImage: `url(${HeroImg})` }}>
      <div className='heroContent'>
        <h1>Willkommen bei der Kaffeebrennerei</h1>
        <p>
          Erlebe erstklassigen und 100% ethischen Kaffeegenuss direkt aus
          unserer Rösterei.
        </p>
        {/* <button className='heroButton' onClick={() => navigate('/produkte')}>
          Mehr erfahren
        </button> */}
        <button className='heroButton' onClick={() => navigate('/')}>
          Mehr erfahren
        </button>
      </div>
    </div>
  )
}

export default Hero
