import React, { useState, useEffect } from 'react'
import './style/ProductPage.css'
import HeroImg from '../resources/fotosKathi/2.jpg'

const KontaktPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='kontaktPage'>
      <div
        className='productPageImageDiv'
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className='textDivProductPage'>
          <h1>Treten Sie mit uns in Kontakt!</h1>
          <p>Das Team der Kaffeebrennereiheißt Sie herzlich Willkommen!</p>
        </div>
      </div>
    </div>
  )
}

export default KontaktPage
