import React, { useEffect, useState } from 'react'
import './style/SlideShow.css'

import pic1 from '../resources/fotosSlideShow/1.jpg'
import pic2 from '../resources/fotosSlideShow/2.jpg'
import pic3 from '../resources/fotosSlideShow/3.jpg'
import pic4 from '../resources/fotosSlideShow/4.jpg'
import pic5 from '../resources/fotosSlideShow/5.jpg'
import pic6 from '../resources/fotosSlideShow/6.jpg'
import pic7 from '../resources/fotosSlideShow/7.jpg'
import pic8 from '../resources/fotosSlideShow/8.jpg'
import pic9 from '../resources/fotosSlideShow/9.jpg'
import pic10 from '../resources/fotosSlideShow/10.jpg'
import pic11 from '../resources/fotosSlideShow/11.jpg'
import pic12 from '../resources/fotosSlideShow/12.jpg'
import pic13 from '../resources/fotosSlideShow/13.jpg'

const images = [
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic9,
  pic10,
  pic11,
  pic12,
  pic13
]

const SlideShow = () => {
  const [current, setCurrent] = useState(0)

  // Automatischer Wechsel
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(prev => (prev + 1) % images.length)
    }, 4000)
    return () => clearInterval(interval)
  }, [])

  const nextSlide = () => {
    setCurrent((current + 1) % images.length)
  }

  const prevSlide = () => {
    setCurrent((current - 1 + images.length) % images.length)
  }

  return (
    <div className='slideshow'>
      <div className='slideshow-wrapper'>
        {images.map((img, index) => (
          <div
            key={index}
            className={`slide ${index === current ? 'active' : ''}`}
          >
            <img src={img} alt={`Slide ${index}`} />
          </div>
        ))}

        <button className='prev' onClick={prevSlide}>
          &#10094;
        </button>
        <button className='next' onClick={nextSlide}>
          &#10095;
        </button>
      </div>
    </div>
  )
}

export default SlideShow
