import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import HeroImg from '../resources/fotosKathi/3.jpg'
import './style/Cart.css'

const Cart = ({ abwesend }) => {
  const [cartItems, setCartItems] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || []
    setCartItems(storedCart)
  }, [])

  const removeItem = index => {
    const updatedCart = cartItems.filter((_, i) => i !== index)
    setCartItems(updatedCart)
    localStorage.setItem('cart', JSON.stringify(updatedCart))
  }

  const clearCart = () => {
    setCartItems([])
    localStorage.removeItem('cart')
  }

  const updateQuantity = (index, newQuantity) => {
    if (newQuantity < 1) return
    const updatedCart = cartItems.map((item, i) =>
      i === index
        ? {
            ...item,
            selectedOptions: {
              ...item.selectedOptions,
              stueckzahl: newQuantity
            }
          }
        : item
    )
    setCartItems(updatedCart)
    localStorage.setItem('cart', JSON.stringify(updatedCart))
  }

  const handleBestellen = () => {
    if (abwesend) {
      alert(
        'Der Shop ist zurzeit geschlossen. Bitte versuchen Sie es später erneut.'
      )
    } else {
      navigate('/checkout')
    }
  }

  return (
    <>
      <div
        className='aktuellesPageImageDiv'
        style={{
          backgroundImage: `url(${HeroImg})`,
          backgroundPosition: 'center'
        }}
      >
        <div className='textDivAktuellesPage'>
          <h1>Warenkorb</h1>
          <p>Hier können Sie Ihren Warenkorb bearbeiten.</p>
        </div>
      </div>
      <div className='cart-container'>
        {cartItems.length === 0 ? (
          <p>Dein Warenkorb ist leer.</p>
        ) : (
          <>
            <ul>
              {cartItems.map((item, index) => (
                <li key={index} className='cart-item'>
                  <img
                    src={item.images[0]}
                    alt={item.name}
                    className='cart-item-image'
                  />
                  <div className='cart-item-details'>
                    <h3>{item.name}</h3>
                    <p>
                      {item.selectedOptions.kaffeeart} |{' '}
                      {item.selectedOptions.mahlgrad} |{' '}
                      {item.selectedOptions.menge}
                    </p>

                    <p>Preis: {item.price} €</p>
                  </div>
                  <div className='cart-item-actions'>
                    <p>
                      Stückzahl:
                      <input
                        type='number'
                        value={item.selectedOptions.stueckzahl}
                        onChange={e =>
                          updateQuantity(index, parseInt(e.target.value) || 1)
                        }
                        min='1'
                        className='quantity-input'
                      />
                    </p>
                    <button
                      className='remove-button'
                      onClick={() => removeItem(index)}
                    >
                      Entfernen
                    </button>
                  </div>
                </li>
              ))}
            </ul>{' '}
            <button
              className='clear-cart-button'
              onClick={() => handleBestellen()}
            >
              Bestellen ({' '}
              {cartItems.reduce(
                (acc, item) =>
                  acc + item.price * item.selectedOptions.stueckzahl,
                0
              )}{' '}
              €)
            </button>
          </>
        )}
        <Link to='/produkte' className='back-to-shop'>
          Zurück zum Shop
        </Link>
      </div>
    </>
  )
}

export default Cart
