import React from 'react'
import Team7 from '../resources/partner/team7.png'
import Gießerei from '../resources/partner/gießerei.png'

import KOBL from '../resources/partner/kobl.webp'

import Guat from '../resources/partner/guat.jpg'
import Riadarei from '../resources/partner/riadarei.jpg'
import Hochleckenhaus from '../resources/partner/hochleckenhaus.png'
import InnviertlerVersailles from '../resources/partner/innviertlerversailes.png'
import EnergieAlm from '../resources/partner/energiealm.png'
import BBRZ from '../resources/partner/bbrz.png'
import './partners.css' // CSS importieren

const partners = [
  { name: 'Team 7', logo: Team7, link: 'https://www.team7.at/' },
  { name: 'Gießerei', logo: Gießerei, link: 'https://www.giesserei-ried.at/' },
  { name: 'EnergieAlm', logo: EnergieAlm, link: 'https://www.energiealm.at/' },
  {
    name: 'Innviertler Versailles',
    logo: InnviertlerVersailles,
    link: 'https://www.innviertler-versailles.at/'
  },
  { name: 'KOBL - Bio in Ried', logo: KOBL, link: 'https://www.kobl.at/' },
  {
    name: 'BBRZ - Berufliches Bildungs- und Rehabilitationszentrum',
    logo: BBRZ,
    link: 'https://www.bbrz.at/'
  },
  { name: 'Guad', logo: Guat, link: 'https://www.guat-taiskirchen.at/' },
  { name: 'Riadarei', logo: Riadarei, link: 'https://www.riadarei.at/' },
  {
    name: 'Hochleckenhaus',
    logo: Hochleckenhaus,
    link: 'https://hochleckenhaus.at/'
  }
]

const Partner = () => {
  return (
    <div className='partners-container'>
      <h1 className='partners-title'>Unsere Partner</h1>
      <div className='partners-grid'>
        {partners.map((partner, index) => (
          <div
            key={index}
            className='partner-logo-div'
            onClick={() => window.open(partner.link, '_blank')}
            style={{ cursor: 'pointer' }} // Zeigt an, dass es klickbar ist
          >
            <div
              key={index}
              className='partner-logo-div'
              onClick={() => window.open(partner.link, '_blank')}
              style={{ cursor: 'pointer' }}
              title='Zur Website'
            >
              <img
                className='partner-logo'
                src={partner.logo}
                alt={partner.name}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Partner
